import { Col, Row } from "react-styled-flexboxgrid";

import HeaderProjects from "../components/sections/HeaderProjects/HeaderProjects.js";
import { Helmet } from "react-helmet";
import Layout from "../components/layout/Layout";
import ProjectCard from "../components/partials/ProjectCard/ProjectCard.js";
import ProjectTypes from "../components/sections/ProjectTypes/ProjectTypes.js";
import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { StyledGrid } from "../helpers/helpers.js";
import { graphql } from "gatsby";

export const TypeRoute = ({ data, pageContext }) => {
  const posts = data.allMarkdownRemark.edges;
  const type = pageContext.type;
  const title = data.site.siteMetadata.title;
  return (
    <Layout>
      <StyledGrid className="projects-page">
        <Row>
          <HeaderProjects {...data.projectsContent.frontmatter} />
          <Col xs={12} md={7} mdOffset={3}>
            <ProjectTypes />
          </Col>
        </Row>
        <ScrollAnimation animateIn="fadeInBottom" duration={1} offset={0}>
          <Row>
            {posts &&
              posts.map(({ node }, i) => (
                <Col xs={12} md={6} lg={4} key={i}>
                  <ProjectCard
                    {...node.frontmatter}
                    {...node.fields}
                    isInList={true}
                  />
                </Col>
              ))}
          </Row>
        </ScrollAnimation>
      </StyledGrid>
      <Helmet title={`${type} | ${title}`} />
    </Layout>
  );
};

export default TypeRoute;

export const typePageQuery = graphql`
  query TypePage($type: String) {
    site {
      siteMetadata {
        title
      }
    }
    projectsContent: markdownRemark(
      frontmatter: { templateKey: { eq: "projects-content" } }
    ) {
      frontmatter {
        title
        highlighted
        description
        featuredImage {
          childImageSharp {
            fluid {
              base64
              aspectRatio
              src
              srcSet
              sizes
            }
          }
        }
      }
    }
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { types: { in: [$type] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            description
            title
            projects
            name
            featuredPost
            date
            client
            types
            projectImages {
              childImageSharp {
                fluid {
                  base64
                  aspectRatio
                  src
                  srcSet
                  sizes
                }
              }
            }
            featuredImage {
              childImageSharp {
                fluid {
                  base64
                  aspectRatio
                  src
                  srcSet
                  sizes
                }
              }
            }
          }
        }
      }
    }
  }
`;
