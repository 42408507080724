import "./ProjectCard.scss";

import GroupButton from "../../partials/GroupButton/GroupButton.js";
import Link from "gatsby-link";
import PreviewCompatibleImage from "../../../components/PreviewCompatibleImage";
import React from "react";

const ProjectCard = ({
  client,
  types,
  date,
  name,
  slug,
  title,
  featuredImage,
  isHorizontal,
  isInList,
}) => {
  return (
    <article
      className={`ProjectCard ${isHorizontal ? "horizontal" : ""} ${
        isInList ? "in-list" : ""
      }`}
    >
      <div className="date">{new Date(date).getFullYear()}</div>
      <Link className="inner-link" to={slug} title={"Voir le projet"}>
        {featuredImage && (
          <PreviewCompatibleImage imageInfo={featuredImage} className="image" />
        )}
        <div className="content">
          <p>Typologie</p>
          <h3>{types[0]}</h3>
          <p>Client</p>
          <h3>{client}</h3>
          <p>Projet</p>
          <h3>{name}</h3>
        </div>
      </Link>
      <GroupButton to={slug} title={"Voir le projet"} text={"DÉCOUVRIR"} />
    </article>
  );
};

export default ProjectCard;
