import "./ProjectTypes.scss";

import { Link, graphql, useStaticQuery } from "gatsby";
import React, { useEffect, useState } from "react";

import { kebabCase } from "lodash";

const TypesPage = () => {
  const data = useStaticQuery(graphql`
    query TypesQuery {
      allMarkdownRemark(limit: 1000) {
        group(field: frontmatter___types) {
          fieldValue
          totalCount
        }
      }
    }
  `);
  const [location, setLocation] = useState("/");
  useEffect(() => {
    setLocation(window.location.pathname);
  }, []);
  const group = data.allMarkdownRemark.group;
  return (
    <ul className="ProjectTypes with-square">
      <li>
        <Link
          to={`/projects/`}
          className={
            location === "/projects" || location === "/projects/"
              ? "active"
              : ""
          }
        >
          Tous
        </Link>
      </li>
      {group.map((type) => (
        <li key={type.fieldValue}>
          <Link
            to={`/projects/${kebabCase(type.fieldValue)}/`}
            className={
              location === `/projects/${kebabCase(type.fieldValue)}/`
                ? "active"
                : ""
            }
          >
            {type.fieldValue}
          </Link>
        </li>
      ))}
    </ul>
  );
};

export default TypesPage;
