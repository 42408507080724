import "./HeaderProjects.scss";

import { Col } from "react-styled-flexboxgrid";
import PreviewCompatibleImage from "../../../components/PreviewCompatibleImage";
import React from "react";

const HeaderProjects = ({ description, title, highlighted, featuredImage }) => {
  return (
    <div className="HeaderProjects">
      <Col xs={12} className="featuredImage">
        <PreviewCompatibleImage imageInfo={featuredImage} className="image" />
      </Col>
      <Col xs={12} className="title">
        <h1>{title}</h1>
        <div className="back">{highlighted}</div>
      </Col>
      <Col xs={12} md={7} mdOffset={3}>
        <div className="subHeader">
          <p>{description}</p>
        </div>
      </Col>
    </div>
  );
};

export default HeaderProjects;
